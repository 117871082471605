<!--
 * @Author: JZX jiangzhixin@nxin.com
 * @Date: 2023-12-19 17:54:38
 * @LastEditors: JZX jiangzhixin@nxin.com
 * @LastEditTime: 2024-01-06 16:17:23
 * @FilePath: \vue-sort\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app" class="all-page">
      <div v-if="isMobile" style="background-color: #21252b;height: 100%;width: 100%;" >
              <div style="display: flex;flex-direction: column;justify-content: flex-start;align-items: center;background-color: #21252b;" class="text-style">
                <p>呦~ </p>
                <p>来了客官~~</p>
                <p>里面请~</p>
                <p>您往这走~</p>
              </div>
                  <div style="text-align: center;">
                  <a href="https://docs.qq.com/sheet/DZkpWZFVMU0tGQ3RF?tab=BB08J2" style="text-align: center;font-size: 36px; font-weight: bold; text-align: center;" class="mc-play" target="_blank">点我一下~宝</a>
              </div>
          </div>
          <el-container class="all-page" v-else>
              <el-aside width="250px" style="display: flex;flex-direction: column;height: 100%;align-items: center;">
                  <div>
                      <img :src="require('@/assets/logo.jpg')" style="width: 100%;">
                  </div>
                  <a href="https://docs.qq.com/sheet/DZkpWZFVMU0tGQ3RF?tab=BB08J2" style="color: #fff;" target="_blank">战力统计表</a>
                  <audio id="music" :src="musicSrc" autoplay loop preload="auto" @play="isPlay = true" @pause="isPlay = false" ></audio>
                  <p style="display: flex;justify-content: space-around;align-items: center;">
                      分组：<el-input-number class="num-input" size="mini" v-model="group" @change="getResult" :min="1" :max="10" label="分组"></el-input-number>
                  </p>
                  <div style="width: 100%;display: flex;justify-content: space-around;margin-bottom: 10px;">
                      <el-button size="mini" @click="drawer = true">查看历史</el-button>
                      <el-button size="mini" @click="textChange">重新分组</el-button>
                      <el-button size="mini" @click="saveImg">保存图片</el-button>
                  </div>
                  <el-input type="textarea" v-model="textarea" :clearable="true" style="margin-bottom: 10px;width: 100%;flex: 1;"></el-input>
              </el-aside>
              <el-main v-loading="loading"  :style="elMain">
                 
                  <!-- el-main可能设置了一些overflow属性，导致截图截不全 所以套一层-->
                  <div id="content" :style="elMain">
                      <!-- 音乐按钮 -->
                      <div class="mc" @click="playMusic" @dblClick="switchMusic" :class="isPlayMc" title="单击暂停，双击切歌">
                          <!-- <i class="el-icon-service"></i> -->
                          <i class="el-icon-headset"></i>
                        
                      </div>
                      <!-- 标题 -->
                      <div :class="textStyle">
                          <span style="font-size:14px;">2887</span>
                          <span>༺琉☬璃༻</span>
                          <span style="font-size:14px;">2887</span>
                      </div>
                      <!-- 排序展示区 -->
                      <div style="display: flex;justify-content: space-around;color: darksalmon;" v-if="textarea">
                          <div class="all-page" v-for="(element, index) in table" :key="index" style="display: flex;flex-direction: column;align-items: center;height: 100%;width: 260px;">
                              <p>{{ `共计${element.length}人` }}</p>
                              <draggable class="draggable" style="width: 80%;display: flex;justify-content: center; flex: 1;" v-model="table[index]" chosen-class="chosen" @add="add" force-fallback="true" :group="draGroup" animation="300" @start="onStart" @end="onEnd">
                                  <transition-group>
                                      <div class="item" :class="{ 'save-image': isSaveImg }" @click.stop="setCaptain(item, index, ind)" v-for="(item, ind) in element" :key="ind">
                                          <span>{{ item.name }}</span>
                                          <el-divider direction="vertical"></el-divider>
                                          <span>{{ item.value }}</span>
                                          <div class="captain" v-if="item.isCaptain">
                                             <i class="el-icon-s-flag"></i>
                                          </div>
                                      </div>
                                  </transition-group>
                              </draggable>
                          </div>
                      </div>
                      <!-- 底部战斗力区 -->
                      <div style="display: flex;justify-content: space-around;" v-if="textarea">
                          <div class="all-page" v-for="(element, index) in table" :key="index" style="display: flex;flex-direction: column;">
                              <p style="color: #e9dc7a;font-size: 18px;" >
                                  <span>战斗力</span>
                                  <el-divider direction="vertical"></el-divider>
                                  <span style="color:#fff">
                                      {{ `${element.reduce((sum, obj) => sum + obj.value, 0).toFixed(2)}` }}
                                  </span>
                              </p>
                          </div>
                      </div>
                  </div>
                

              </el-main>
          </el-container>
          <el-drawer title="历史记录" :visible.sync="drawer" :show-close="false" direction="ttb" size="400px" custom-class="el-drawer-bg">
              <el-carousel :interval="4000" height="300px" type="card" indicator-position="none" :autoplay="false" arrow="always" style="width: 80%;margin-left: 10%;">
                  <el-carousel-item v-for="item in historyData" :key="item.date" style="background: #ccc; ">
                      <!-- <img :src="item.img" style="display: block;width: 100%;"> -->
                      <el-image style="width: 100%;height: 100%;" :src="item.img" fit="none" :preview-src-list="getSrcList(item.img)"></el-image>
                      <p style="position: fixed; bottom: 10px; text-align: center;width: 100%; color:#e17055;">{{ item.date }}</p>
                  </el-carousel-item>
                </el-carousel>
          </el-drawer>
    </div>
</template>

<script>
import html2canvas from '@/assets/html2canvas.min.js';
// import { ClipboardItem } from 'clipboard-polyfill';
export default {
  name: 'app',
  created() {
      console.log(this)
      this._isMobile()
      if (this.isMobile) {
          return
      }
      let table = localStorage.getItem('table')
      let textarea = localStorage.getItem('textarea')
      table && (this.table = JSON.parse(table))
      textarea && (this.textarea = textarea)
      textarea && this.textChange()
      this.switchMusic()
      const historyData = localStorage.getItem('historyData')
      historyData && (this.historyData = JSON.parse(historyData))
  },
  data() {
      const musicList = [
          {name: '英雄联盟无限火力', value: require('@/assets/英雄联盟无限火力.mp3')},
          {name: 'All-For-Love' , value: require('@/assets/All-For-Love.mp3')},
          {name: '夏日漱石', value: require('@/assets/夏日漱石.mp3')},
          {name: 'shots', value: require('@/assets/shots.mp3')},
          {name: 'wake', value: require('@/assets/wake.mp3')},
          {name: 'RISE', value: require('@/assets/RISE.mp3')}
      ]
      return {
          isSaveImg: false, // 保存图片前改变样式
          loading: false,
          radio: 1,
          textarea: '', // 文本
          data: [], // 处理后的数据
          group: 4, // 分为几组
          table: [],
          club: [],
          waitData: [],
          drag:false,
          draGroup:{
              name:'site',
              pull:true, //可以拖从
              put:true//可以拖出
          },
          musicSrc: '',
          musicList,
          textStyle: 'text-style',
          elMain: '',
          isPlay: false, // 音乐是否播放,
          isPlayMc: 'mc-play', // 默认样式
          isMobile: false,
          drawer: false,
          form: {},
          historyData: []
      }
  },
  mounted() {
      if (this.isMobile) {
          return
      }
      this.$alert('', '欢迎来到一键盐场', {
      confirmButtonText: '确定',
      center: true,
      callback: action => {
          this.$nextTick(() => {
              const play = document.getElementById('music')
              console.log('play',play)
              play.volume = 0.5
              play.play()
              this.isPlay = true
          })
      }
      });
  },
  methods: {
    // 判断是否为移动端
    _isMobile() {
        console.log(navigator.userAgent)
        let isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        this.isMobile = !!(isMobile && isMobile.length)
        console.log('this.isMobile',this.isMobile)
    },
    // 文本改变 存到本地，进行解析
    textChange() {
        localStorage.setItem('textarea',this.textarea)
        if (this.radio == 1) {
            const arr = this.textarea.split('\n')
            const res = []
            arr.forEach(item => {
                let a = item.split('\t') // ['宇神', '108']
                res.push({ name: a[0], value: Number(a[1]) || 0, isCaptain:false })
            })
            // const res = arr.split('\t')
            this.data = res
            this.getResult()
            return
        }
        const arr = this.textarea.split('\n')
        const res = []
        arr.forEach(item => {
            let a = item.split(' ')
            res.push({ name: a[1], value: a[2] || 0 })
        })
        this.data = res
        this.getResult()
    },
    // 保存图片并复制
    saveImg () {
        const _this = this
        this.changeStyle()
        setTimeout(()=>{
            const content = document.getElementById('content')
            this.loading = true
            html2canvas(content).then(canvas => {
                // 功能一： 复制到粘贴板
                canvas.toBlob(async blob => {
                    const data = [new ClipboardItem({[blob.type]: blob})]
                    console.log('data',data)
                    await navigator.clipboard.write(data).then (() => {
                        _this.loading = false
                        _this.$message({
                            message: '图片复制成功！',
                            type: 'success'
                        });
                        _this.backStyle()
                    })
                })
                // 功能二： 转换成图片路径保存下来
                var newCanvas = document.createElement('canvas');
                var context = newCanvas.getContext('2d');
                newCanvas.width = canvas.width;
                newCanvas.height = canvas.height;
                context.drawImage(canvas, 0, 0);
                // 将新Canvas转换为图片的Base64编码字符串
                const image = newCanvas.toDataURL('image/png');
                let obj = {
                    date: new Date().toLocaleString(),
                    img: image
                }
                this.historyData.push(obj)
                // 历史记录只保存10条
                this.historyData.length > 10 && this.historyData.shift()
                // 全部存一遍
                localStorage.setItem('historyData', JSON.stringify(this.historyData))
                localStorage.setItem('textarea',this.textarea)
                localStorage.setItem('table', JSON.stringify(this.table))
            })
        },0)
        
    },
    // 保存图片前 改变样式
    changeStyle() {
        this.isSaveImg = true
        this.textStyle = 'save-text-style'
        this.elMain = {backgroundImage:`url(${require('@/assets/bg2.jpg')})`, background: '#21252b'}
    },
    // 之前样式
    backStyle() {
        this.isSaveImg = false
        this.textStyle = 'text-style'
        this.elMain= {backgroundImage:`url(${require('@/assets/bg2.jpg')})`}
    },
    // 设置队长
    setCaptain(item, index) {
        const { name } = item
        item.isCaptain = true
        this.table[index].forEach(item => {
            if (item.name !== name) { item.isCaptain = false}
        })
    },
    // 分组算法
    getResult() {
        if (!this.group || !this.data.length) {
            this.$message('数据有误，请检查数据');
            return
        }
        let arr = this.data;
        let r = this.group; // number of groups
        // 将数组中的对象按照value值排序
        arr.sort((a, b) => b.value - a.value);
        // 创建一个长度为r的数组，每个元素都是一个空数组
        let groups = Array.from({length: r}, () => []);
        // 创建一个长度为r的数组，每个元素都是0
        let sums = Array.from({length: r}, () => 0);
        // 创建一个空对象，用于存储名称和组的映射
        let nameGroupMap = {};
        // 遍历数组
        for (let obj of arr) {
            // 如果名称已经存在于映射中
            if (nameGroupMap[obj.name] !== undefined) {
                // 获取该名称对应的组的索引
                let existingGroupIndex = nameGroupMap[obj.name];
                // 将对象添加到对应的组中
                groups[existingGroupIndex].push(obj);
                // 更新对应组的总和
                sums[existingGroupIndex] += obj.value;
            } else {
                // 如果名称不存在于映射中，找到总和最小的组的索引
                let minSumIndex = sums.indexOf(Math.min(...sums));
                // 找到组大小最小的值
                let minGroupSize = Math.min(...groups.map(group => group.length));
                // 找到所有大小等于最小值的组
                let minSizeGroups = groups.filter(group => group.length === minGroupSize);
                // 计算这些组的总和
                let minSizeGroupSums = minSizeGroups.map(group => group.reduce((sum, obj) => sum + obj.value, 0));
                // 找到总和最小的组
                let minSumInMinSizeGroups = Math.min(...minSizeGroupSums);
                // 找到目标组的索引
                let targetGroupIndex = groups.indexOf(minSizeGroups[minSizeGroupSums.indexOf(minSumInMinSizeGroups)]);
                // 将对象添加到目标组中
                groups[targetGroupIndex].push(obj);
                // 更新目标组的总和
                sums[targetGroupIndex] += obj.value;
                // 在映射中添加名称和组的映射
                nameGroupMap[obj.name] = targetGroupIndex;
            }
        }
        this.table = groups
        localStorage.setItem('table', JSON.stringify(this.table))
    },
    //开始拖拽事件
    onStart(){
        this.drag=true;
    },
    //拖拽结束事件
    onEnd() {
        this.drag=false;
    },
    add(e) {
        const { from, oldIndex, to, newIndex } = e
        console.log('e',e)
        let fromIndex = this.getIndex(from) // 从第几个来
        let toIndex = this.getIndex(to) // 到第几个去
        console.log(fromIndex, toIndex)
        if (fromIndex == toIndex) {
            return
        }
        const obj = this.table[fromIndex][oldIndex] // 选中的元素
        console.log('obj',obj)
        // this.table[fromIndex].splice(oldIndex, 1) // 从来的地方删除
        // this.table[toIndex].splice(newIndex,0,obj) // 添加到新的地方
    },
    getIndex (node) {
        var child = node.parentNode.parentNode; // 结构可能会因为改变html而改变
        var i = 0;
        while((child = child.previousSibling) != null) i++;
        return i
    },
    // 播放或暂停音乐
    playMusic() {
        const music = document.getElementById('music')
        if (this.isPlay) {
            music.pause()
            this.isPlayMc = 'mc-stop'
        } else {
            music.play()
            this.isPlayMc = 'mc-play'
        }
    },
    // 切换音乐
    switchMusic () {
        const num = Math.floor(Math.random()*5)
        this.musicSrc = this.musicList[num].value
    },
    getSrcList (src) {
        return [src]
    }
}
}
</script>

<style>
html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
  user-select: none;
}

.all-page {
  width: 100%;
  height: 100%;
}

.el-textarea__inner {
  height: 100%;
  background-color: #000 !important;
  width: 100%;
  color: #afafaf !important;
  border: 1px dashed #6d6d6d;
}

/* 表格的滚动条隐藏 */
.el-aside,
textarea {
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: rgba(213, 215, 220, 0.3);
    border: none;
  }

  &::-webkit-scrollbar-track {
    border: none;
  }
}

.el-header,
.el-footer {
  background-color: #B3C0D1;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-aside {
  background-color: #21252b;
  color: #fff;
  text-align: left;
}

.el-main {
  background-size: cover;
  background-image: url('~@/assets/bg2.jpg');
  background-repeat: no-repeat;
  background-position: center;
  color: #fff;
  text-align: center;
}

.mc {
  position: fixed;
  top: 10px;
  right: 20px;
  font-size: 25px;
  cursor: pointer;
}

.mc-play {
  animation: changeColor 10s linear infinite;
}

.mc-stop {
  color: #fff;
}

#app>.el-container {
  width: 100%;
  background-color: #21252b;
  height: 100%;
}
.el-drawer-bg {
  background: #d1d1d1;
}
.el-drawer__header {
  /* background: #d1d1d1; */
  text-align: center;
  color: #0099ff;
  font-weight: bolder;
}
.el-drawer__body {
  background: #d1d1d1;
}

.num-input>.el-input>input {
  background: #000;
  color: #fff;
}

/*被拖拽对象的样式*/
.item {
  font-size: 16px;
  height: 30px;
  background-color: #fdfdfd;
  border: solid 1px #eee;
  margin-bottom: 10px;
  cursor: move;
  color: #6e388f;
  width: 100%;
  border-radius: 15px;
  background: rgb(238, 174, 202);
  background: radial-gradient(circle, rgba(238, 174, 202, 1) 0%, rgba(148, 187, 233, 1) 100%);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
}

.item .captain {
  position: absolute;
  right: -20px;
  top: -15px;
  color: red;
  font-size: 30px;
  transform: rotate(24deg);
}

.save-image {
  font-weight: bold;
  height: 35px;
  font-size: 20px;
}

.item:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

/*选中样式*/
.chosen {
  border: solid 2px #3089dc !important;
}

.draggable {
  /* border: 1px dashed darkslategray; */
}

.draggable>span {
  display: block;
  width: 100%;
}

.text-style {
  margin: 0 auto;
  width: 300px;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  background: linear-gradient(135deg, #14ffe9, #ffeb3b, #ff00c0);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  animation: ff 3s linear infinite;
}

.save-text-style {
  margin: 0 auto;
  width: 300px;
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  color: transparent;
  background-image: url('~@/assets/title.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

}

@keyframes ff {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes changeColor {
  0% {
    color: #fe0000;
  }

  10% {
    color: #ff7200;
  }

  20% {
    color: #fef105;
  }

  30% {
    color: #03d609;
  }

  40% {
    color: #0099ff;
  }

  50% {
    color: #ee01f1;
  }

  60% {
    color: #ffeaa7;
  }

  70% {
    color: #ff7675;
  }

  80% {
    color: #fd79a8;
  }

  90% {
    color: #e17055;
  }

  100% {
    color: #e84393;
  }
}

canvas {
  position: fixed;
  width: 100%;
  height: 100%;
}</style>
