/*
 * @Author: JZX jiangzhixin@nxin.com
 * @Date: 2023-12-19 17:54:38
 * @LastEditors: JZX jiangzhixin@nxin.com
 * @LastEditTime: 2023-12-20 16:17:40
 * @FilePath: \vue-sort\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import draggable from 'vuedraggable'

Vue.config.productionTip = false
Vue.component('draggable', draggable)

new Vue({
  render: h => h(App),
}).$mount('#app')
